.bulk-toc-update-form {
  margin-bottom: 5rem;
  margin-top: -3rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .valid-update-request-button {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 6rem;
  }

  .checkbox-date-calander-container {
    display: flex;

    .checkbox {
      margin-top: 7rem;
      label {
        margin-top: 0.5rem;
      }
      input {
        margin-top: 0.4rem;
        padding: 0.85rem;
      }
    }

    .toc-reason-select {
      margin-top: 3.5rem;

      div {
        font-size: 1.4rem;
      }
    }

    .date-calander {
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
      }

      input::placeholder {
        font-size: 1.4rem;
        color: #4a4a55;
        opacity: 0.7;
        font-family: 'Montserrat', sans-serif;
      }

      .mantine-InputWrapper-root {
        width: 27.4rem;
        margin-top: 3rem;
        height: 5rem;
        border-radius: 5px;
        margin-right: -0.5rem;

        button {
          border-radius: 5px;
          font-size: 13px;
          height: 5rem;
          font-family: 'Montserrat', sans-serif;

          svg {
            overflow: visible;
          }

          span {
            font-size: 1.4rem;
            opacity: 0.7;
            color: #4a4a55;
          }
        }

        button:disabled {
          opacity: 1;
          background-color: #fafafc;
        }

        table {
          width: 25rem;
          tbody tr td button {
            font-size: 13px;
            height: 3rem;
            width: 3rem;
          }
          thead tr th {
            font-size: 13px;
          }
        }
      }
    }

    .input-text-area {
      margin-bottom: 2rem;
      margin-top: 2rem;
      width: 45rem;

      label {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.4rem;
        font-weight: 100;
      }

      ::placeholder {
        color: #797d8c;
      }

      textarea {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.4rem;
        height: 8rem;
      }
    }
  }
}

.date-info-field {
  display: flex;
  .start-date-info-icon {
    margin-top: 6rem;
    margin-left: 1rem;
  }
}
