.remove-icon {
  display: flex;
  margin-left: -2rem;
  width: 3px;
  color: rgb(5, 10, 0);
  cursor: pointer;
  justify-content: center;
}

.google-maps-icon {
  display: block;
  width: 2rem;
  height: 2rem;
}

.google-maps-icon-disabled {
  display: block;
  width: 2rem;
  height: 2rem;
  cursor: not-allowed;
  opacity: 0.5;
}

.mantine-DatePickerInput-input {
  font-size: 1.3rem;
  padding: 9px;
  border: 1px solid #d1d1d1;
  border-radius: 3px;
}

.date-input {
  color: #000;
}
