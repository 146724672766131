.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
  margin-top: 1rem;
}

.ceS4EgxnSPoT9JOwqjeP {
  height: 1rem;
}
