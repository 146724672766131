.steet-view-tooltip {
  background-color: #27273f;
  color: white;
}

.street-view-tooltip-text {
  font-family: 'Montserrat', sans-serif;
  margin-top: 1.2rem;
  font-size: 1.3rem;
  margin-right: 1rem;
}

.google-maps-content {
  display: flex;
  background-color: #27273f;
  color: white;
  padding: -5px;
  border-radius: 5px;
}