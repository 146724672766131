.frame-details-toggle {
  margin-bottom: 2rem;
}

.frame-details-table-container {
  @extend .existingTocPoc-table-container;
  tbody tr td {
    font-family: 'Montserrat', sans-serif;
    color: #474646;
    font-weight: 400;
  }
}
