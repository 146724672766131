.unit-details-container {
  width: 80rem;
  margin-top: 4rem;
  margin-left: 2rem;
}

.unit-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.detail {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 48%;
  margin-bottom: 10px;
  span {
    &:first-child {
      margin-right: 10px;
      font-weight: bold;
    }
    &:last-child {
      text-align: right;
    }
  }
}
.detailValue {
  background-color: #a8adbd;
  padding: 5px;
  border-radius: 9px;
  color: #000000;
}
