@import '@clearchannelinternational/ecooh-system/styles.css';
@import '@clearchannelinternational/ecooh-system/icons.css';
@import '../../components/header/header.scss';
@import '../../components/footer/footer.scss';
@import '../../components/sidebar/sidebar.scss';
@import '../../components/searchBox/searchBox.scss';
@import '../../components/searchField/searchField.scss';
@import '../../components/debouncedInput/debouncedInput.scss';
@import '../../components/dateRangeInput/dateRangeInput.scss';
@import '../../components/table/table.scss';
@import '../tocPocTabSidebarTab/tocPocDetailsTable/tocPocActions/resyncModal/resyncModal.scss';
@import '../auditViewSidebarTab/auditTableChangeDetails/auditTableChangeDetails.scss';
@import '../../components/displayUnitDetails/displayUnitDetails.scss';
@import '../tocPocTabSidebarTab/tocPocSidebarTab.scss';
@import '../tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tocPocDetailsTab/tocPocDetailsTab.scss';
@import '../tocPocTabSidebarTab/tocPocDetailsTable/tabsComponent/tocPocDetailsTab/renderInputFields/rederInputFields.scss';
@import '../tocPocTabSidebarTab/tocPocDetailsTable/tocPocDetailsTable.scss';
@import '../../components/steetView/streetView.scss';
@import '../../components/alert/alert.scss';
@import '../bulkOOCSearchSidebarTab/bulkOOCSearchSidebarTab.scss';
@import '../../components/CustomDatePickerInput/customDatePickerInput.scss';
@import '../bulkOOCSearchSidebarTab/bulkTOCUpdateModal/bulkTOCUpdateModalDetails/bulkTOCUpdateModalDetails.scss';
@import '../bulkOOCSearchSidebarTab/bulkTOCUpdateModal/bulkTOCFormFieldsValidation/bulkTOCFormFieldsValidation.scss';
@import '../../pages/tocPocTabSidebarTab/tocPocDetailsTable/tocPocActions/FrameSearchDetailsModal/frameDetailsTable/frameDetailsTable.scss';
@import '../openingHoursSidebarTab/openingHoursSIdebarTab.scss';
// TODO Use generic colors passed as variable in scss files: P3
body {
  overflow-y: scroll;
  scroll-behavior: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  body {
    font-family: 'Montserrat', sans-serif;
    min-height: 100vh;
    height: calc(100vh - 100px);
    display: flex;
  }
}

.accountImg {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -2rem;
  margin-left: 1.8rem;
  height: 12rem;
  z-index: 970;
}

.hammer {
  position: absolute;
  margin-top: -1.5rem;
  height: 6.5rem;
}

.remove-icon {
  width: 2.3rem;
  height: 2.3rem;
  margin-left: 0.9rem;
}

.Main {
  display: flex;
}

.tab-container {
  margin-top: 7rem;
  max-width: 100%;
  overflow-x: hidden;
}

.loading {
  margin-left: 45rem;
  margin-top: 10rem;
}

.toc-poc-tab-container {
  [role='radio'] {
    padding: 0;
  }

  .tab-label {
    padding: 1.9rem 2.7rem 1.5rem 2rem;
  }
}

.sign-out {
  margin-left: 3rem;
}
