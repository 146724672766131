.comments-info-container {
  display: flex;

  .comments-info {
    margin-top: 1.8rem;
    [data-tippy-root] .tippy-content {
      background-color: #27273f;
      border-radius: 5px;
      color: white;
    }
  }
}

.audit-change-details-icon {
  cursor: pointer;
}

.mantine-CloseButton-root{
  // margin-left: -1rem;
}

.or-text{
  margin-top: 2.5rem;
  margin-left: 5.5rem;
}

.new-line {
  white-space: pre-line;
}