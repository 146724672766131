.bulk-ooc-sidebar-tab {
  margin-left: 2rem;
  margin-top: 2.5rem;

  .mantine-InputWrapper-label {
    font-size: 1.4rem;
    margin-right: -7rem;
  }

  .mantine-Input-wrapper {
    input {
      font-size: 1.4rem;
    }
  }

  .bulk-update-export-search-button {
    float: right;
    margin-top: 2rem;
    margin-right: 5.2rem;
    display: flex;
    gap: 2rem;
    // margin-right: -200rem;
    // margin-left: 20rem;
  }
  .auto-refresh {
    margin-left: 2rem;
    display: flex;
    gap: 1rem;

    button {
      padding: 0.8rem;
    }
    label {
      margin-top: 1rem;
      font-size: 1.5rem;
    }
    input {
      margin-top: 0.5rem;
      padding: 0.85rem;
      zoom: 130%;
    }
  }
}

.bulk-summaries-loading {
  margin-top: 10rem;
}

.bulk-ooc-search-container {
  .bulk-buttons-container {
    display: flex;
    margin-left: 45rem;
    gap: 1rem;
    .search-button {
      button {
        width: 20rem;
      }
      // margin-left: 24rem;
    }

    .reset-button {
      button {
        width: 20rem;
      }
    }
  }

  .bulk-ooc-search-bar {
    font-family: 'Montserrat', sans-serif;
    margin-top: 4rem;
    margin-left: 2.2rem;
    border: 1px solid #dde1f1;
    background-color: #f5f6fa;
    width: 130rem;
    border-radius: 5px;

    .search-button {
      margin-left: 22rem;
      margin-top: 1.1rem;
    }

    .horizontal-line {
      border-top: 1.3px dotted #000000;
      margin-top: 1.5rem;
      width: 100%;
    }

    .toc-poc-checkbox {
      margin-left: 35rem;
      .externalID-checkboxes {
        margin-left: 14rem;
        margin-top: -0.3rem;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        input {
          padding: 0.8rem;
        }

        label {
          margin-right: 2rem;
          margin-top: 0.5rem;
        }
      }
    }

    .checkbox-calender-container {
      display: flex;

      .event-status-checkbox {
        border-right: 1px solid #b5b9cb;
        margin-top: 0.2rem;
        .status-text {
          margin-left: 1rem;
        }

        .externalID-checkboxes {
          margin-left: 1rem;
          margin-bottom: 1rem;
        }

        .externalID-checkboxes-container {
          margin-top: -2rem;
          input {
            margin-top: 1rem;
            padding: 0.85rem;
          }

          label {
            margin-right: -8rem;
            margin-top: 1.2rem;
          }
        }
      }
    }

    .toc-reason-field {
      margin-left: 1rem;
      margin-bottom: 1rem;
      // z-index: 999;

      label {
        margin-bottom: 1.7rem;
        width: 20rem;
      }

      .mantine-MultiSelect-label {
        font-size: 1.6rem;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
      }

      .mantine-MultiSelect-input {
        border: 1px solid #d1d1d1;
        ::placeholder {
          color: hsl(211, 10%, 50%);
        }
        min-width: 30rem;
        min-height: 4.9rem;

        ::placeholder {
          color: #737f8c;
        }
      }

      .mantine-MultiSelect-searchInput {
        font-size: 1.5rem;
        margin-top: 1rem;
        font-family: 'Montserrat', sans-serif;
      }

      .mantine-MultiSelect-dropdown {
        div {
          font-family: 'Montserrat', sans-serif;
          font-size: 1.4rem;
        }
      }
      .mantine-MultiSelect-defaultValueLabel {
        font-size: 1.4rem;
        font-family: 'Montserrat', sans-serif;
      }

      .mantine-ScrollArea-root {
        min-height: 35rem;
        padding: 1rem;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(209, 209, 209);
      }
    }
    .toc-reason-field-container {
      border-right: 1px solid #b5b9cb;
      padding: 5px;
    }

    .administration-branch-field {
      @extend .toc-reason-field;
      margin-top: 0.5rem;
      .mantine-MultiSelect-input {
        min-width: 33rem;
      }
    }
  }
}

.date-picker-radio-calender-container {
  border-right: 1px solid #b5b9cb;
  margin-left: 1rem;
  margin-top: 0.2rem;
  input {
    padding: 0.8rem;
  }

  label {
    margin-right: -5rem;
    margin-top: 0.5rem;
  }

  .checkbox-date-calander-container {
    display: flex;
    margin-left: 3rem;

    .checkbox {
      margin-top: 1.9rem;
    }

    .date-calander {
      margin-right: 1.2rem;

      input::placeholder {
        font-size: 1.4rem;
        color: #4a4a55;
        opacity: 0.7;
        font-family: 'Montserrat', sans-serif;
      }

      .mantine-InputWrapper-root {
        width: 27.4rem;
        margin-top: 1rem;
        height: 5rem;
        border-radius: 5px;
        margin-right: -0.5rem;

        button {
          border-radius: 5px;
          font-size: 13px;
          height: 5rem;
          font-family: 'Montserrat', sans-serif;

          svg {
            overflow: visible;
          }

          span {
            font-size: 1.4rem;
            opacity: 0.7;
            color: #4a4a55;
          }
        }

        button:disabled {
          opacity: 1;
          background-color: #fafafc;
        }

        table {
          width: 25rem;
          tbody tr td button {
            font-size: 13px;
            height: 3rem;
            width: 3rem;
          }
          thead tr th {
            font-size: 13px;
          }
        }
      }
    }

    .comparison-operator {
      margin-right: 8rem;
      .mantine-InputWrapper-root {
        margin-left: -3rem;
        margin-top: 1.8rem;
        width: 4.2rem;
      }

      .mantine-Select-option {
        div {
          height: 20rem;
        }
      }

      .mantine-Input-input {
        height: 3rem;
        width: 11rem;
        border: 1px solid #d1d1d1;
        font-family: 'Montserrat', sans-serif;
        ::placeholder {
          color: hsl(211, 10%, 50%);
        }
      }

      .mantine-Select-input {
        height: 3rem;
        border: 1px solid #d1d1d1;
        ::placeholder {
          color: hsl(211, 10%, 50%);
          font-size: 10.4rem;
        }
      }

      .mantine-Select-wrapper {
        width: 11rem;
        margin-top: 1rem;
        ::placeholder {
          color: hsl(211, 10%, 50%);
        }
      }

      .mantine-Select-dropdown {
        div {
          font-family: 'Montserrat', sans-serif;
          padding: -2rem;
          width: 10.8rem;
        }
      }

      .mantine-ScrollArea-root {
        width: 1rem;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(209, 209, 209);
      }
    }
  }
}

.batch-description-text {
  background-color: transparent;
  border: none;
  padding: 0;
  font: inherit;
  color: rgb(73, 73, 255);
  text-decoration: underline;
  cursor: pointer;
}
.bulk-search-tab-loading {
  margin-top: 5rem;
}
.refresh-text {
  margin-left: 2rem;
  margin-bottom: -5rem;
}

.fetched-records-text {
  margin-bottom: -4rem;
}
.bulk-update-modal-success-message {
  margin-top: -8.8rem;
}
.bulk-search-button-tooltip-text {
  font-family: 'Montserrat', sans-serif;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  font-size: 1.3rem;
  margin-right: 1rem;
}
.summary-text {
  margin-bottom: -1rem;
}
.detailed-text {
  margin-top: 6rem;
  margin-bottom: -4rem;
}
.bulk-table-container {
  margin-top: 5rem;
}
.bulk-ooc-table-text-container {
  @extend .audit-table-container;
  margin-top: 10rem;
}
.criteria-label {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  margin: 2.4rem 0 0 -3rem;
  width: 12rem;
}
