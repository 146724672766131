.calendar-config-container {
  .save-discard-button-container {
    float: right;
    display: flex;
    gap: 2rem;
    margin-top: -5rem;
  }
  .bottom-save-discard-button-container {
    @extend .save-discard-button-container;
    margin-top: -3rem;
    margin-bottom: 2rem;
  }
}
.oh-date-warning {
  margin-top: 3.5rem;
  margin-left: 0.8rem;
}

.opening-hours-new-button {
  visibility: hidden;
  Button {
    padding: 1.5rem;
    right: 2rem;
  }
  margin: 0 2.5rem 0 140rem;
}

.opening-hours-tabs {
  margin: 12rem 0 0 2rem;
}

.full-calendar-container {
  margin-bottom: 4rem;
  margin-top: 3rem;
}

.text-delete-button-container {
  display: flex;
  .delete-button {
    margin-left: 1rem;
    Button {
      width: 2.4rem;
      height: 1.8rem;
      padding: 0.3rem;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
    background-color: black;
  }
}

.checkbox-oh {
  display: flex;
  margin-left: 10 rem;
  margin-bottom: -4rem;
}

.checkbox-container {
  zoom: 102%;
  display: flex;
  gap: 0.5rem;
  margin-top: 5.1rem;
  margin-left: 11rem;
  position: absolute;
  z-index: 1;
}

$days-widths: (
  'sat': 22rem,
  'sun': 24rem,
  'tue': 19rem,
  'wed': 20rem,
  'thu': 20rem,
  'mon': 20rem,
  'fri': 20rem,
);

@each $day, $width in $days-widths {
  .fc-day-#{$day} {
    width: $width;
  }
}

.daysOfWeek {
  display: flex;

  .dayButton {
    margin-right: 0.5rem;
    border-radius: 1rem;
    padding: 0.3rem;
    background-color: rgb(211, 211, 211);
    border: 1px solid rgb(0, 0, 0);
    font-weight: 600;
  }
  .filled {
    color: rgb(242, 242, 242);
    background-color: #3888d8;
  }
}

.identifiers-text {
  margin: 0 0 -5rem 2rem;
}

.frame-type-text {
  @extend .identifiers-text;
  margin-bottom: -1rem;
  display: flex;
}

.day-part-details-text {
  margin: 2rem 0 -8rem 2rem;
}
.calander-view-event-text {
  margin-left: 1rem;
  margin-bottom: -2.5rem;
}
.oh-tabs-component {
  margin-top: -10rem;
}
.fc-day-other {
  visibility: hidden;
}

.fc-day-today {
  background-color: inherit !important;
}

.opening-hours-date-picker-input {
  @extend .mantine-date-picker-input;
  width: 28rem;
}
.merged-cells {
  background-color: rgb(255, 255, 255);
  padding-top: 1rem;
  padding-right: 1rem;
//   padding-left: 2rem;
  position: absolute;
//   margin-left: -1.85rem;
  width: 15rem;
  z-index: 1;
}

.different-cells {
  @extend .merged-cells;
  margin-top: -2rem;
  padding-top: -3.1rem;
  background-color: transparent;
}

.empty-field {
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 20;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 2rem;
  padding-bottom: 3.5rem;
  margin-top: -2.5rem;
  width: 8rem;
  margin-left: -1rem;
}
// .effective-from-date-calander {
//   //   margin-left: 45rem;
//   margin-right: 25rem;
//   margin-top: 5rem;
// }

.effective-date-warning {
  margin-top: 2.5rem;
}

.date-calendar-container {
  margin-right: 25rem;
  margin-top: 4rem;
  align-items: center;
  display: flex;

  .sibling-panels-sync-checkbox {
    align-self: flex-end;
    margin-left: 52rem;
    position: absolute;
    margin-bottom: 4rem;
  }
}

.radio-button-container {
  background-color: rgb(255, 255, 255);
  padding-top: 1rem;
  //   padding-right: 7.5rem;
  padding-left: 2rem;
  position: absolute;
  margin-top: -2rem;
  margin-left: -1rem;
  width: 8rem;
  z-index: 999;
}

.select-radio-button {
  transform: scale(1.5);
  z-index: -1;
}
.varied-openinghours-banner {
  display: flex;
  justify-content: flex-start;
  margin-left: 2rem;
}

.pending-actions {
  background-color: #ffbfbf; // Light red
  color: #3e3e3e; // Dark gray
  padding: 10px;
  border-radius: 5px;
  font-weight: 550;
  font-size: 1.5rem;
  padding-left: 2rem;
  width: 18rem;
}
.different-opening-hours-banner {
  @extend .warning-banner;
  display: flex;
  background-color: transparent;
  border-color: rgb(17, 17, 17);
  padding-bottom: 0.3rem;
}

.current-future-op-text {
  @extend .day-part-details-text;
}
