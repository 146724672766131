.poc-input-fields-container {
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
  }

  input {
    margin-left: 0.5rem;
    padding: 0.8rem;
  }

  .checkbox-label {
    color: black;
    font-size: 1.4rem;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .dismantle-site-checkbox-container {
    display: flex;
  }

  .poc-date-picker-input-container {
    margin-top: 1.5rem;
    display: inline-block;
  }
}

.toc-poc-input-fields-container {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .panel-no {
    margin-left: -1.5rem;
  }
}

.date-label {
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  opacity: 0.8;
}
