.table-container {
  margin-top: 6rem;
  position: relative;
  left: 2.2rem;
  margin-bottom: 3rem;
  margin-right: 5rem;
  // width: 154rem;

  .box-container {
    display: flex;
  }

  .box {
    background-color: #92a7fc;
    border-radius: 0.5rem;
    border: 2px solid #6c2f7c;
    color: #000000;
    font-weight: bold;
    margin-right: 0.01rem;
    width: 11.5rem;
    padding: 10px 1px 10px 5px;
    font-size: 1rem;
    margin-bottom: -2.3rem;
    cursor: pointer;
  }

  .box.active {
    color: #000000;
  }

  .box.disabled {
    opacity: 0.7;
  }

  .tocpoc-button-container {
    display: flexbox;
  }

  .new-toc-button {
    background-image: linear-gradient(360deg, #656a71, #0072ff);
    border-radius: 0.5rem;
    border: 2px solid #6c2f7c;
    color: #ffffff;
    font-weight: bold;
    margin-right: 0.01rem;
    width: 8rem;
    height: 2.3rem;
    padding: 8px 2px 10px 1px;
    font-size: 1rem;
    cursor: pointer;
  }

  tbody tr td {
    text-align: left;
    padding: 1rem 1rem;
    border-top: 0.0625rem solid #dee2e6;
    font-family: 'Roboto';
    font-size: 14px;
    color: black;
  }
  thead tr th {
    // text-align: center;
    background-color: #f5f6fa;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }
}

.success-message {
  margin-top: 8rem;
  color: #000000;
  width: 40rem;
  background-color: #53d15b;
  padding: 10px 20px;
  border-radius: 4px;
  opacity: 1;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-new-button-container {
  display: flex;
  margin: 20px;

  .search-all-audit-records-button {
    Button {
      padding: 1.6rem;
      position: relative;
      margin-left: 10rem;
    }
    margin-top: 1rem;
    height: 70px;
  }

  .new-button {
    Button {
      padding: 1.5rem;
      position: absolute;
      right: 2rem;
    }
    margin-top: 1.2rem;
  }

  Button {
    padding: 1.5rem;
    position: fixed;
    right: 4rem;
  }
}

.search-input-container {
  display: flex;
  flex: 1;
}

.search-input {
  padding: 15px;
  width: 45rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.search-icon-container {
  width: 4rem;
  padding: 10px 10px 7px 10px;
  border-radius: 4px;
  margin-left: -4.5rem;
  margin-top: 0.3rem;
}

.search-icon {
  width: 100%;
  height: auto;
  cursor: pointer;
  position: relative;
}

.search-bar {
  display: flex;
  border: 1px solid #dde1f1;
  background-color: #f5f6fa;
  width: 83.9rem;
  border-radius: 5px;
  padding-left: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  height: 5rem;

  gap: 5px;
  font-family: 'Montserrat', sans-serif;

  .mantine-date-picker-input {
    width: 25rem;
    margin-top: 0.5rem;

    table {
      tbody tr td button {
        font-size: 33px;
        height: 3rem;
        width: 3rem;
      }
      thead tr th {
        font-size: 13px;
      }
    }
  }

  .select-container {
    z-index: 10;
    span {
      font-size: 1.5rem;
    }
  }

  label {
    font-size: 1.5rem;
    margin-right: -8rem;
    margin-top: 1.5rem;
  }

  Button {
    padding: 1.56rem;
    position: relative;
    margin-left: 3.45rem;
    margin-right: -4.1rem;
  }
}

.warning-banner {
  background-color: #ffcc00;
  color: #333;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #ff9900;
  border-radius: 4px;
  text-align: center;
}
