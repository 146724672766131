.mantine-Autocomplete-input {
  position: relative;
  margin-top: 0.1rem;
  height: 4.8rem;
  width: 26.9rem;
  border: 1.2px solid #dde1f1;

  &:focus {
    border: 1.5px solid #000000;
  }

  input {
    // border: 1.3px solid #dde1f1;

    height: 20rem;
    width: 27rem;
  }
}
.react-autosuggest__input {
  padding: 15px;
  &:focus {
    outline: none;
    border-color: #000000;
  }

  &::placeholder {
    font-size: 1.5rem;
    color: #666675;
    font-family: 'Montserrat', sans-serif;
  }
}
.react-autosuggest__suggestions-container {
  display: none;
  position: absolute;
  top: 2.3rem;
  left: 8.8rem;
  max-height: 240px;
  width: 11.1rem;
  overflow-y: auto;
  background-color: #fff;
  // border: 0.2px solid #0262fc;
  font-size: small;
  border-radius: 0 10px 10px 10px;
  z-index: 2;
}
.react-autosuggest__suggestions-container--open {
  text-align: left;
  display: flex;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
}
.react-autosuggest__suggestion {
  padding: 3px 8px;
  cursor: pointer;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #eee;
}

.mantine-Autocomplete-error {
  margin-top: -6.7rem;
}
