.headerContainer {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
}

.frameManagerText {
  position: absolute;
  z-index: 960;
  top: 1.6rem;
  margin-left: -14rem;
  font-size: 10rem;
}
