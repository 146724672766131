.sidebarz {
  position: fixed;
}

.sideBar {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
}

.Sidebar {
  height: calc(85vh - 100px);
}

.Sidebar-heading {
  font-size: 24px;
}
.Sidebar-tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10rem;
  .border {
    border-top: 1px solid #000000;
    padding-top: 1rem;
  }
}
.Sidebar-tab {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 1rem;
  margin-left: 2rem;
  font-weight: normal;
  background: #ffffff;
  color: #000000;
  text-align: center;
  &.active {
    font-weight: 500;
    background-color: #e4edff;
    color: #005aea;
  }
}
