.search-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loader-container {
  margin-left: 1rem;
  position: absolute;
}

.no-suggestions-message {
  margin-bottom: -1.4rem;
  margin-left: 0.5rem;
  font-size: 1.15rem;
  color: #66b3ff;
}

.search-form-field {
  display: flex;
  flex-direction: start;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.8rem;
  margin-left: 1rem;

  input::placeholder {
    font-size: 1.4rem;
    color: #4a4a55;
    opacity: 0.7;
    font-family: 'Montserrat', sans-serif;
  }

  .mantine-InputWrapper-root {
    width: 27.4rem;
    margin-top: 1rem;
    height: 5rem;
    border-radius: 5px;
    margin-right: -0.5rem;

    button {
      border-radius: 5px;
      font-size: 13px;
      height: 5rem;
      font-family: 'Montserrat', sans-serif;

      svg {
        overflow: visible;
      }

      span {
        font-size: 1.4rem;
        opacity: 0.7;
        color: #4a4a55;
      }
    }

    button:disabled {
      opacity: 1;
      background-color: #fafafc;
    }

    table {
      width: 25rem;
      tbody tr td button {
        font-size: 13px;
        height: 3rem;
        width: 3rem;
      }
      thead tr th {
        font-size: 13px;
      }
    }
  }
}
.checkbox-round {
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 31px;
  height: 20px;
  outline: none;
  background: #e3e3e3;
  border: 1px solid #010101;
  cursor: pointer;
  &:checked {
    background: radial-gradient(circle at center, #ffffff 25%, #e2e2e2 20%);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0.35rem;
      left: 0.6rem;
      width: 0.45rem;
      height: 0.45rem;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #000;
    }
  }
}
label {
  font-size: 1.3rem;
  width: 17rem;
}
input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
}
