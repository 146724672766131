.status-box {
  color: #000000;
  border-radius: 7px;
  text-align: center;
  width: 8.5rem;
  padding: 2px;
}

.syncStatusCell {
  border-radius: 0.9rem;
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 1.4rem;
}

.buttonContainer {
  display: flex;

  Button {
    margin-right: 1rem;
  }
}

.tooltip-column {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column-header {
  white-space: pre-wrap;
}

.digital-unit-ids-with-street-view {
  display: flex;
  align-items: center;
}
