.date-range-input-container {
  align-items: center;
  margin-left: 0.5rem;
  label {
    font-size: 11px;
    margin-right: 8px;
  }
  input {
    width: 120px;
    margin-bottom: 0.2rem;
  }
  span {
    margin-right: 8px;
  }
}
