.alert {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 4rem;
}

.Success {
  background-color: #e2fce1;
  margin-top: 3.5rem;
}

.Deleted {
  background-color: #e2fce1;
  margin-top: 3.5rem;
}

.Pending {
  background-color: #fde2aa;
  color: white;
}

.Failed {
  background-color: #ffdddd;
  color: white;
  margin-top: 3.5rem;
}

.close {
  float: right;
  cursor: pointer;
}

.alert-text {
  font-size: 1.6rem;
}

.pre-wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
