.validation-result {
  margin-top: 2rem;

  .batch-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -4rem;
    margin-bottom: 5rem;
  }

  .ineligible-tocs-table {
    margin: 0 auto 5rem;
    text-align: center;
  }

  .horizontal-line {
    border-top: 1.3px dotted #000000;
    border-spacing: 0 80px;
    width: 200%;
    margin-left: -20rem;
    margin-bottom: 1rem;
  }

  .submit-bulk-update {
    // left: 50%;
    margin: auto auto auto auto;
    // margin-left: 24.5rem;
  }

  .eligible-TOCs-message {
    text-align: center;
  }
}

.horizontal-line-white-border {
  border-top: 1.3px dotted #ffffff;
  border-spacing: 0 80px;
  width: 200%;
  margin-left: -20rem;
  margin-bottom: 1rem;
}
