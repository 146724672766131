.start-date-highlight {
  background-color: #228be6;
  color: black;
  border-radius: 3px;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 0.8rem;
  padding-top: 0.4rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.23rem;
}

.current-date-highlight {
  // background-color: #bbbbbb;
  border: 1px solid black;
  color: black;
  border-radius: 12px;
  padding: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}
