.cc-ds-select {
  margin-top: 2.5rem;

  label {
    font-size: 1.4rem;
  }
}

.existing-toc-poc-accordion-sitekey-column {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accordian-container {
  margin-bottom: 2rem;
  border: 1px solid #ccd0e0;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(241, 217, 221);

  .accordian-content {
    display: flex;
    margin-top: -3rem;
    gap: 2rem;

    .accordian-item {
      margin-top: 1rem;
      margin-right: -8rem;
    }

    label {
      font-weight: bold;
      font-size: 13.5px;
    }
    span {
      font-size: 13.5px;
    }

    margin-bottom: 5rem;
  }

  .accordian-content > div {
    width: calc(33.33% - 2rem);
    margin-bottom: -4.5rem;
  }
}

.date-pickerinput-container {
  margin-top: 2.4rem;
}

.mantine-DatePickerInput-label {
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  color: #424356;
  span {
    color: red;
  }
}

.mantine-date-picker-input {
  width: 25rem;
  margin-top: 0.5rem;

  button {
    font-size: 13px;
    height: 4.85rem;
    font-family: 'Montserrat', sans-serif;
    // border: 1.4px solid rgb(173, 177, 198);
  }

  button:disabled {
    opacity: 1;
    background-color: #fafafc;
  }

  table {
    tbody tr td button {
      font-size: 13px;
      height: 3rem;
      width: 3rem;
    }
    thead tr th {
      font-size: 13px;
    }
  }
}

.toc-poc-details {
  .horizontal-line {
    border-top: 1.3px dotted #000000;
    margin-bottom: 2rem;
    opacity: 0.4;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .toc-poc-radio-container {
    display: flex;
    margin-top: 1rem;
    label {
      margin-right: -10rem;
      margin-top: -0.4rem;
    }

    .toc-poc-radio {
      margin-left: 2rem;
      display: flex;

      input {
        margin-left: 0.5rem;
        padding: 0.8rem;
      }
      input[type='radio']:checked:disabled {
        background: #ffffff;
      }
      input[type='radio']:disabled + label {
        color: rgb(69, 63, 63);
      }
    }
  }

  .createTocPoc-search-container {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    // margin-left: -0.2rem;
    display: flex;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    }

    Button {
      padding: 1rem;
      height: 4.8rem;
      width: 4.8rem;
      margin-left: -0.5rem;
      margin-bottom: 1rem;
      background-color: #f5f6fa;
    }
  }

  .details {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;

      .display-unit-details-conatiner {
        margin-top: 2rem;
        margin-bottom: -6rem;
      }
    }

    .double-sided-panel-checkbox {
      display: flex;

      .panel-type-heading {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.4rem;
      }

      .panel-type {
        margin-left: 0.7rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.4rem;
        margin-bottom: 3.5rem;
      }

      input {
        margin-left: 0.5rem;
        margin-top: -0.1rem;
        padding: 0.8rem;
      }
      input[type='checkbox']:checked:disabled {
        background: #34373a;
      }
      input[type='checkbox']:disabled {
        border: 1px solid black;
        opacity: 0.5;
      }
      input[type='checkbox']:disabled + label {
        color: rgb(131, 125, 125);
        // border: 0.1px solid black;
      }
    }
  }

  .externalID-checkboxes {
    margin-left: 1rem;
    margin-top: -0.3rem;
    display: flex;
    flex-wrap: wrap;
    input {
      margin-left: 0.5rem;
      padding: 0.8rem;
    }

    input[type='checkbox']:disabled + label {
      color: rgb(131, 125, 125);
    }

    input[type='checkbox']:checked:disabled {
      background: #8f9399;
    }
    input[type='checkbox']:disabled + label {
      color: rgb(131, 125, 125);
    }
  }

  .externalID-checkboxes-container {
    display: flex;
    // flex-wrap: wrap;
    margin-bottom: 2rem;

    .checkbox-label {
      color: black;
      font-size: 1.4rem;
    }

    label {
      margin-right: -8.4rem;
      // margin-top: -0.4rem;
    }

    input {
      padding: 0.8rem;
    }
    input[type='checkbox']:checked:disabled {
      background: rgb(185, 179, 179);
    }
    input[type='checkbox']:disabled + label {
      color: rgb(90, 85, 85);
    }
    label:disabled {
      color: black;
    }
    label {
      margin-top: 0.5rem;
    }
  }

  .reduced-opacity {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .search-containerreduced-opacity .tab-heading1 {
    margin-top: 3rem;
  }
  .tab-heading2 {
    margin-top: 8rem;
  }

  .display-unit-details-conatiner {
    margin-top: -2rem;
    margin-left: -0.5rem;

    tbody tr td {
      text-align: left;
      padding: 1rem 1rem;
      border-top: 0.0625rem solid #dee2e6;
      font-family: 'Roboto';
      color: black;
      font-size: 14px;
    }
    thead tr th {
      // text-align: center;
      background-color: #f5f6fa;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
    }
  }

  .edit-tocPoc-details-container {
    margin-top: -5rem;
  }

  .display-unit-details {
    display: flex;
    flex-wrap: wrap;
    margin-top: -3rem;
    gap: 2.8rem;

    > * {
      margin-bottom: -3.7rem; /* Adjust the margin-bottom between elements as needed */
    }

    input {
      font-family: 'Montserrat', sans-serif;
      opacity: 60%;
    }

    textarea {
      font-family: 'Montserrat', sans-serif;
      width: 70rem;
      opacity: 60%;
    }
  }
}
.audit-table-container,
.existingTocPoc-table-container {
  .common-field {
    // background-color: #d3fddc;
    border-radius: 5px;
    padding: 6px;
  }

  tbody tr td {
    text-align: left;
    padding: 1rem 1rem;
    border-top: 0.0625rem solid #dee2e6;
    font-family: 'Roboto';
    color: black;
    font-size: 14px;
  }
  thead tr th {
    // text-align: center;
    .mantine-TableHeadCell-Content {
      gap: 0.4rem;
    }
    button {
      font-size: 14px;
    }
    input {
      font-size: 1.3rem;
    }
    svg {
      transform: scale(1.5);
      margin-left: 0.1rem;
    }

    .mantine-MultiSelect-defaultValueLabel {
      font-size: 1.4rem;
    }
    background-color: #f5f6fa;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }
}

.mantine-Menu-itemLabel {
  font-size: 1.3rem;
}

.mantine-MultiSelect-item {
  font-size: 1.3rem;
}

.mantine-Select-item {
  font-size: 1.3rem;
}

.mantine-TableHeadCell-Content-Wrapper {
  margin-right: 0.5rem;
}

.audit-table-container {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 4rem;
  // position: relative;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;

  .mantine-Paper-root {
    overflow: scroll;
  }

  .mantine-TextInput-wrapper {
    input {
      font-size: 1.4rem;
      padding: 1.9rem;
    }
  }

  .mantine-Checkbox-inner {
    padding: 1rem;
    input {
      margin-left: -1.1rem;
      margin-top: -1rem;
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  .mantine-DatePickerInput-input {
    width: 13rem;
    border: 1px solid rgb(119, 117, 117);
    background: transparent;
  }

  .mantine-Alert-message {
    font-size: 1.4rem;
  }
}

.display-unit-details-accordion-title {
  margin-left: 0rem;
  padding-top: 1.4rem;
  margin-bottom: -0.8rem;
}

#item-0-header {
  padding: 0;
  margin-left: 0.5rem;
}

.hidden {
  opacity: 0.4;
}

.ooc-detail-tab {
  // overflow: scroll;
}

.mantine-Switch-track {
  width: 2.5rem;
  height: 1.5rem;
}

.mantine-Button-label {
  font-size: 1.3rem;
}
